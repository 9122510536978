import React, { useState } from 'react'

import Glider from 'react-glider'
import 'glider-js/glider.min.css'
import Link from '../Link/Link'
import { useEffect } from 'react'
import Loading from '../Loading/Loading'
import { isProductionEnvironment } from '../../helpers/helpers'

export const SocialUpdates = () => {
  const [facebookPosts, setFacebookPosts] = useState([])

  const parseFacebookDate = (responseDate) => {
    const parsedDate = Date.parse(responseDate)
    return new Date(parsedDate).toLocaleDateString()
  }

  useEffect(() => {
    const hasPosts = localStorage.posts && localStorage.posts.length > 0
    const isPostsNeedingUpdate =
      !Date.parse(localStorage.postsDateRetrieved) || Date.parse(localStorage.postsDateRetrieved) < Date.now() - 60000;

    if (hasPosts && !isPostsNeedingUpdate) {
      setFacebookPosts(JSON.parse(localStorage.posts))
    } else {
      fetch(isProductionEnvironment ? 'https://api.regisroadrunners.co.uk/facebook-feed' : 'http://localhost:5000/facebook-feed')
        .then((response) => response.json())
        .then((responseData) => {
          const filteredResponse = responseData.data && responseData.data.filter(post => post.message)
          setFacebookPosts(filteredResponse)
          localStorage.setItem('posts', JSON.stringify(filteredResponse))
          localStorage.setItem('postsDateRetrieved', new Date().toString())
        })
    }
  }, [])

  const hasFacebookPosts = facebookPosts.length > 0 && Array.isArray(facebookPosts)

  return (
    <div className="container">
      {!facebookPosts.length
        ? <Loading />
        : <Glider
          slidesToShow={1}
          slidesToScroll={1}
          draggable
          scrollLock
          dragVelocity={1}
          hasDots
          hasArrows
          responsive={[
            {
              breakpoint: 900,
              settings: {
                // Set to `auto` and provide item width to adjust to viewport
                slidesToShow: facebookPosts.length < 2 ? facebookPosts.length : 2,
                slidesToScroll: facebookPosts.length < 2 ? facebookPosts.length : 2,
              }
            },
            {
              breakpoint: 1500,
              settings: {
                // Set to `auto` and provide item width to adjust to viewport
                slidesToShow: facebookPosts.length < 3 ? facebookPosts.length : 3,
                slidesToScroll: facebookPosts.length < 3 ? facebookPosts.length : 3,
              }
            },
          ]}>
          {hasFacebookPosts && facebookPosts.map((post, key) => (
            <div key={key} className='slide'>
              <Link url={post.permalink_url}>
                <article className='social-article'>
                  <div className='social-message'>
                    <p className='bold'>Posted {parseFacebookDate(post.created_time)}</p>
                  </div>
                  {/* <div className='social-message__image-container'>
                  <picture className='thumbnail'>
                    <img src='/RRR_Parkrun.jpg' />
                  </picture>
                </div> */}
                  <div>
                    <p className='social-message-spaced-paragraph'>"{post.message}"</p>
                    {/* {post.full_picture && (<img src={post.full_picture} />)}  */}
                  </div>
                  <div className='social-message__link-text'>
                    <p className='bold'>See Post <i className="fa-solid fa-arrow-right fa-xl icon__spacing-left"></i></p>
                  </div>
                </article>
              </Link>
            </div>
          ))}
        </Glider>}
    </div >
  )
}

export default SocialUpdates