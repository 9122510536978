export const EVENTS_CONFIG = {
  sectionId: 'events',
  heading: 'Events',
  cards: [
    {
      imgSrc: '/rrr_parkrun_littlehampton.jpg',
      imgYBackgroundPosition: '15%',
      imgXBackgroundPosition: '50%',
      heading: 'Parkruns',
      copy: [
        'Parkrun (stylised as parkrun) is a collection of 5-kilometre (3.1 mi) events for walkers, runners and volunteers that take place every Saturday morning.',
        'Bognor is the most popular choice of parkrun location for Regis Road Runners, taking place at Hotham Park for 4 laps around the park.',
        'Regis Road Runners also take great enjoyment in touring many different parkruns both locally and also across the country'
      ],
      links: [
        {
          buttonText: 'Explore Bognor Regis Parkrun',
          url: 'https://www.parkrun.org.uk/bognorregis/',
          isButtonLink: true
        }
      ]
    },
    {
      imgSrc: '/christmas.jpg',
      imgYBackgroundPosition: '70%',
      imgXBackgroundPosition: '50%',
      heading: 'Christmas Lights Runs',
      copy: [
        `Christmas is certainly a magical time and it's no exception for Regis Road Runner's weekly runs during the festive period in December.`,
        'During this time, Regis Road Runners look for the most scenic routes to run to enjoy the many lights scattered across the area.',
        'The best routes yearly are found around the Pagham and Bognor estates and we welcome all to put on their best santa hat, reindeer outfit or anything festive.'
      ],
      links: [
        {
          buttonText: 'See one of our 2022 festive runs',
          url: 'https://www.facebook.com/RegisRoadRunners/posts/pfbid06KHvWcqW1Jac869v3MMgLwrdyrNBVVHxY7Nt3uuxRt9XDuu8DP9iGJo1BDtAyWbLl',
          isButtonLink: true,
        }
      ]
    },
    {
      imgSrc: '/rrr_benidorm_2023.jpg',
      imgYBackgroundPosition: '85%',
      imgXBackgroundPosition: '50%',
      heading: 'National and International Running',
      copy: [
        'We pride ourselves in representing the Regis Road Runners across many organised running events, both nationally and internationally.',
        'There are many events to get involved with that can be found across a variety of different websites.',
        'Internationally speaking, the Benidorm Half Marathon is a yearly tradition among the club to participate in one of the most popular events in Spain and a great way to enjoy a holiday all at the same time!'
      ],
      links: [
        {
          buttonText: 'Explore running events in the UK',
          url: 'https://www.letsdothis.com/gb/running-events',
          isButtonLink: true
        }
      ]
    },
  ]
}