export const SOCIAL_MEDIA_FEED_CONFIG = {
  sectionId: 'socialMediaFeed',
  heading: 'Social Media Feed',
  carouselImages: [
    {
      src: '/RRR_Parkrun.jpg',
      alt: 'Parkrun'
    },
    {
      src: '/RRR_Parkrun.jpg',
      alt: 'Parkrun'
    },
    {
      src: '/RRR_Parkrun.jpg',
      alt: 'Parkrun'
    },
    {
      src: '/RRR_Parkrun.jpg',
      alt: 'Parkrun'
    },
    {
      src: '/RRR_Parkrun.jpg',
      alt: 'Parkrun'
    }
  ]
}