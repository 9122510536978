import React from 'react'
import { ICON_CLASSES } from '../../constants/icon.constants'
import Link from '../Link/Link'

export const ContactUsPanel = (props) => {
  const {
    heading = '',
    sectionId = '',
  } = props

  return (
    <section id={sectionId} className='section'>
      <div className='section-heading'>
        <h2>{heading}</h2>
      </div>
      <div>
        <div style={{ padding: '0' }} >
          <div className='contact-us-section'>
            <div className='contact-us__content'>
              <div className='contact-us__left contact-us__box'>
                <div className='contact-us__upper'>
                  <h3>Contact Details</h3>
                  {/* <div className='phone'>
                    <a href='tel:+44 7940 331537'><i className='fas fa-phone-volume'></i>+44 7940 331537</a>
                  </div> */}
                  <div className='email'>
                    <a href='mailto:regisroadrunners@gmail.com'><i className='fas fa-envelope'></i>regisroadrunners@gmail.com</a>
                  </div>
                </div>
              </div>
              <div className='contact-us__middle contact-us__box'>
                <h3>Social Media</h3>
                <div className='contact-us__media-icons'>
                  <a href='https://www.facebook.com/RegisRoadRunners/' target='_blank' rel='noopener noreferrer' aria-label="Link to Facebook page for Regis Road Runners"><i className={ICON_CLASSES.FACEBOOK}></i></a>
                  {/* <a href='https://api.whatsapp.com/send?phone=%2B447940331537'><i className={ICON_CLASSES.WHATSAPP}></i></a> */}
                  <a href='https://strava.app.link/swF1WRHf7wb' target='_blank' rel='noopener noreferrer' aria-label="Link to Regis Road Runners Strava page"><i className={ICON_CLASSES.STRAVA}></i></a>
                </div>
              </div>
              <div className='contact-us__right contact-us__box'>
                <h3>Weekly Run Starting Points</h3>
                <p style={{ marginTop: 0 }}>
                  The Regis Road Runners run from a variety of locations, our main location is West Park Cafe in Bognor Regis.
                  Keep an eye out on our socials each Tuesday to see the location of the week, which can include starting points such as:
                </p>
                <div className='contact-us__list'>
                  <ul>
                    <li>
                      <Link url='https://goo.gl/maps/6E6p3o6VHNT9HNkY7'>
                        Bognor Regis - West Park Cafe
                      </Link>
                    </li>
                    <li>
                      <Link url='https://goo.gl/maps/3rm1gckjuvU5txLP7'>
                        Bognor Regis - The Martlets Pub
                      </Link>
                    </li>
                    <li>
                      <Link url='https://goo.gl/maps/jN9fJsyvsAHZ7VDw5'>
                        Bognor Regis - Charlie Purley
                      </Link>
                    </li>
                    <li>
                      <Link url='https://goo.gl/maps/HtFthSzc5TterdMs5'>
                        Felpham - Grassmere Car Park
                      </Link>
                    </li>
                    <li>
                      <Link url='https://goo.gl/maps/Xzk1wy8jDfvinEj1A'>
                        Pagham - The Bear Inn
                      </Link>
                    </li>
                    <li>
                      <Link url='https://goo.gl/maps/YJQE5spQs9Dc78Q37'>
                        Pagham - Pagham Beach Club Car Park
                      </Link>
                    </li>
                  </ul>
                  <p>
                    During the summer months, we also have 'Away Days' to places such as East Dean, Centurion way and Swanbourne Lake.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}