import React from 'react'

export const Link = props => {
  const {
    url = '',
    target = '_blank',
    download = '',
    children
  } = props
  return (
    <>
      {download ? (
        <a
          href={url}
          rel='noopener noreferrer'
          target={target}
          download={download}
        >{children}</a>
      ) : (
        <a
          href={url}
          rel='noopener noreferrer'
          target={target}
        >{children}</a>
      )}
    </>
  )
}

export default Link