import React from 'react'
import Button from '../Button/Button'
import CheckList from '../CheckList/CheckList'

export const SplitPanel = (props) => {
  const {
    sectionId = '',
    heading = '',
    copy = [],
    checkList = '',
    links = [],
    image = ''
  } = props

  return (
    <section id={sectionId} className='section'>
      <div className='section-heading'>
        <h2>{heading}</h2>
      </div>
      <div className='split-panel-main-container'>
        <div className='split-panel-left-container'>
          <img alt={image.alt} src={image.src} className='responsive-image' />
        </div>
        <div className='split-panel-right-container'>
          <div className='split-panel-padding'>
            {copy && copy.map((paragraph, key) => (
              <p key={`splitPanelParagraph${key}`}>{paragraph}</p>
            ))}
            {checkList && (
              <CheckList checkList={checkList} />
            )}
            <div className='split-panel-buttons'>
              {links && links.map((link, key) => (
                <Button key={`splitPanelButton${key}`} {...link} isButtonInline />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SplitPanel