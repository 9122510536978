import React from 'react'

import Button from '../Button/Button'

export const Popup = props => {
  const {
    heading,
    subheading,
    copy = [],
    image = {},
    buttonText,
    buttonIcon,
    onClose,
    showModal
  } = props

  return (
    <div className={`popup ${showModal ? 'popup-visible' : ''}`} id="popup">
      <div className="popup__content">
        <h2>{heading}</h2>
        <h3 className="popup__subheading">{subheading}</h3>
        {image && (
          <img src={image.src} alt={image.alt} />
        )}
        {copy && (
          <div className='popup__text-container'>
          {copy.map((paragraph, key) => (
            <p key={key} className="popup__text">{paragraph}</p>
          ))}
          </div>
        )}
        <div className='popup__button-container'>
          <Button hasButtonIcon onClick={onClose} buttonText={buttonText} icon={buttonIcon}></Button>
        </div>
      </div>
    </div>
  )
}

export default Popup