import React from 'react'
import Button from '../Button/Button'
import SocialUpdates from '../SocialUpdates/SocialUpdates'

export const SocialMediaFeed = props => {
  const {
    sectionId,
    heading,
  } = props

  return (
    <section id={sectionId} className='section'>
      <div className='section-heading'>
        <h2>{heading}</h2>
      </div>
      <SocialUpdates />
      <div className='social-card-bottom'>
        <Button
          url='https://www.facebook.com/RegisRoadRunners/'
          buttonText='See all Regis Road Runners posts'
          isButtonLink />
      </div>
    </section>
  )
}

export default SocialMediaFeed