export let getCookie = cookieName => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const removeAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    document.cookie = cookies[i] + "=; expires=" + new Date(0).toUTCString();
  }
}
