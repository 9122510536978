import { ICON_CLASSES } from "../../constants/icon.constants"

export const POPUP_KEYS = {
  WELCOME: 'Welcome to Regis Road Runners'
}

export const POPUP_CONFIG = {
  [POPUP_KEYS.WELCOME]: {
    heading: 'Welcome to the new Regis Road Runners website!',
    image: {
      src: '/transparent-panel-logo.png',
      alt: 'Regis Road Runners Logo'
    },
    copy: [
      'Welcome to our brand new website! We aim to provide continuous improvement to this site to bring you all the latest news and opportunites that Regis Road Runners has to offer.'
    ],
    buttonText: 'Go to site',
    buttonIcon: ICON_CLASSES.CHECK,
    validUntil: new Date('2023-05-05T22:00:00.00Z')
  }
}