import React from 'react'

export const FeaturedEvent = (props) => {
  const {
    sectionId,
    heading,
    subheading,
    images
  } = props

  return (
    <section id={sectionId} className='featured-event'>
      <div >
        <div className='section-heading'>
          <h2>{heading}</h2>
          {subheading && (
            <p>{subheading}</p>
          )}
        </div>

        <section className='featured-grid'>
          {images.map((image, key) => (
            <div key={`featuredImage${key}`} className='featured-grid-item'>
              <img src={image.src} alt={image.alt} className='responsive-image' />
            </div>
          ))}
        </section>
      </div>
    </section>
  )
}

export default FeaturedEvent