
export const EVENT_KEYS = {
  LONDON_MARATHON: 'London Marathon',
  ANNIVERSARY: 'ANNIVERSARY'
}

export const FEATURED_EVENT_CONFIG = {
  [EVENT_KEYS.LONDON_MARATHON]: {
    sectionId: 'featuredBognor10k',
    heading: 'Featured Event: Bognor 10k 2023',
    images: [
      {
        src: '/images/featured-event/rrr_bognor_10k_2023_1.jpg',
        alt: 'Picture of the Regis Road Runners at the Bognor 10k 2023'
      },
    ],
    subheading:
      `The Regis Road Runners were out in full force at the annual Bognor 10k event on the 14th of May 2023. A huge well done to all those who took part and also to the fabulous supporters, you all made us proud!`,
    validUntil: new Date('2023-06-14T17:00:00.00Z')
  },
  [EVENT_KEYS.ANNIVERSARY]: {
    sectionId: 'featured25thAnniversary',
    heading: 'Regis Road Runners Celebrates 25 Years!',
    images: [
      {
        src: '/images/featured-event/rrr_anniversary_2023.jpg',
        alt: 'Picture of the Regis Road Runners at the Bognor Regis Parkrun'
      },
    ],
    subheading:
      `The Regis Road Runners celebrated their 25th anniversary on the 14th October 2023. 25 years of many achievements, memories and incredible runs both nationally and globally!`,
    validUntil: new Date('2023-11-14T17:00:00.00Z')
  }
}