/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from 'react'
import '../../App.css'
import { CSSTransition } from 'react-transition-group'
import { ICON_CLASSES } from '../../constants/icon.constants'

export const Header = () => {
  const [isNavVisible, setNavVisibility] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(true)
  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 1)
      );
    }
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 900px)')
    mediaQuery.addEventListener('change', handleMediaQueryChange)
    handleMediaQueryChange(mediaQuery)

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }

  const toggleNav = () => {
    isSmallScreen && setNavVisibility(!isNavVisible)
    isSmallScreen && window.pageYOffset < 1 && isNavVisible ? setSmall(false) : setSmall(true)
  }

  return (
    <header className={`header ${small || isNavVisible ? 'header-filled' : ''}`}>
      <img src='/logo-header.png' className='logo' alt='logo' />
      <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames='nav-animation'
        unmountOnExit
      >
        <nav className={`nav ${small || isNavVisible ? 'nav-links__black' : 'nav-links__white'}`}>
          <a href='#about-us' onClick={toggleNav}>About us</a>
          <a href='#events' onClick={toggleNav}>Events</a>
          <a href='#membership' onClick={toggleNav}>Membership</a>
          <a href='#socialMediaFeed' onClick={toggleNav}>Social Media Feed</a>
          <a href='#contactUs' onClick={toggleNav}>Contact us</a>
          <div>
            <div className='header__media-icons'>
              <a href='https://www.facebook.com/RegisRoadRunners/' target='_blank' rel='noopener noreferrer' aria-label="Link to Facebook page for Regis Road Runners"><i className={ICON_CLASSES.FACEBOOK}></i></a>
              <a href='https://strava.app.link/swF1WRHf7wb' target='_blank' rel='noopener noreferrer' aria-label="Link to Regis Road Runners Strava page"><i className={ICON_CLASSES.STRAVA}></i></a>
              <a href='mailto:regisroadrunners@gmail.com' aria-label="Email Regis Road Runners"><i className={ICON_CLASSES.EMAIL}></i></a>
            </div>
          </div>
        </nav>
      </CSSTransition>
      <button onClick={toggleNav} className={`burger ${small || isNavVisible ? 'burger-black' : 'burger-white'}`}>
        ☰
      </button>
    </header>
  )
}

export default Header