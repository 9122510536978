import React from 'react'
import Button from '../Button/Button'

export const OverviewCards = (props) => {
  const {
    sectionId,
    heading,
    copy,
    cards,
  } = props
  return (
    <section id={sectionId} className='section'>
      <div >
        <div className='section-heading'>
          <h2>{heading}</h2>
        </div>
        {copy && copy.map((paragraph, key) => (
          <p key={`overviewHeadingParagraph${key}`}>{paragraph}</p>
        ))}
        <section className='cards'>
          {cards.map((cardConfig, key) => (
            <article key={`overviewCardArticle${key}`} className='card'>
              <div style={{
                backgroundImage: `url(${cardConfig.imgSrc})`,
                backgroundPosition: `${cardConfig.imgXBackgroundPosition} ${cardConfig.imgYBackgroundPosition}`
              }} className='card__picture' />
              <div className='card-content'>
                <div className='card-header'>
                  <h3>{cardConfig.heading}</h3>
                </div>
                {cardConfig.copy && cardConfig.copy.map((cardParagraph, key) => (
                  <p key={`overviewCardParagraph${key}`}>{cardParagraph}</p>
                ))}
              </div>

              <div className='card-buttons'>
                {cardConfig.links && cardConfig.links.map((link, key) => (
                  <Button key={`overViewCardButton${key}`} {...link} />
                ))}
              </div>
            </article>
          ))}
        </section>
      </div>
    </section>
  )
}

export default OverviewCards