import React from 'react'

export const CheckList = (props) => {
  const { checkList } = props
  const { listItems } = checkList

  return (
    <div className='check-list'>
      <ul className='checkmark'>
        {listItems && listItems.map((listItem, key) => (
          <li key={`checkListItem${key}`}>{listItem}</li>
        ))}
      </ul>
    </div>
  )
}

export default CheckList