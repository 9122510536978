import React from 'react'

export const Button = (props) => {
  const {
    buttonText = '',
    url = '',
    isButtonLink = false,
    isDisabled = false,
    onClick = () => { },
    buttonType = 'button',
    isButtonInline = false,
    icon,
    hasButtonIcon = false,
  } = props

  return (
    <>
      {isButtonLink ? (
        <a href={url} rel={'noopener noreferrer'} target='_blank'>
          <button
            disabled={isDisabled}
            onClick={onClick}
            className={isButtonInline ? 'button button__inline' : 'button'}
            type={buttonType}
          >
            {icon && (
              <i className={`${icon} icon__spacing`}></i>
            )}
            {buttonText}
          </button>
        </a>
      ) : (
        <button
          disabled={isDisabled}
          onClick={onClick}
          className={isButtonInline ? 'button button__inline' : 'button'}
          type={buttonType}
        >
          {hasButtonIcon && (
            <i className={`${icon} icon__spacing`}></i>
          )}
          {buttonText}
        </button>
      )}
    </>
  )
}

export default Button