import React from 'react'

export const IntroductionPanel = () => {
  return (
    <section id='introductionPanel'>
      <div className='introduction-panel'>
        <h1>Welcome to Regis Road Runners</h1>
        <img alt='Regis Road Runners Logo' src='/transparent-panel-logo.png' />
      </div>
    </section>
  )
}

export default IntroductionPanel