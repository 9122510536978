import { ICON_CLASSES } from '../constants/icon.constants'

const newMembershipButtonText = 'Download New Member Form'
const disabledNewMembershipButtonText = 'New Members Signup Currently Unavailble'

const isNewMembershipFormAvailable = () => {
  // const today = new Date()
  // const currentYear = today.getFullYear()
  // const startDate = Date.parse(`01 Jan ${currentYear} 00:00:00`)
  // const endDate = Date.parse(`06 April ${currentYear} 00:00:00`)

  // return today.valueOf() >= startDate.valueOf() && today.valueOf() <= endDate.valueOf() // true
  return true
}

export const MEMBERSHIP_CONFIG = {
  sectionId: 'membership',
  heading: 'Membership',
  image: {
    src: '/rrr_membership.jpg',
    alt: 'Regis Road Runners in Sidlesham'
  },
  checkList: {
    listItems: [
      '£20 per year',
      'Receive a discount on a variety of running events',
      'A range of England Athletic discounts on running gear',
      'Covered under club insurance',
      'Be registered as part of a UK Athletics Club',
      'A Club ballot entry for the London Marathon Club Space',
      'Club status for parkrun and events',
      'Be a part of an ever growing club of passionate runners'
    ]
  },
  links: [
    {
      buttonText: isNewMembershipFormAvailable() ? newMembershipButtonText : disabledNewMembershipButtonText,
      url: '/documents/regis-road-runners-new-membership.pdf',
      isButtonLink: true,
      isDisabled: !isNewMembershipFormAvailable(),
      icon: isNewMembershipFormAvailable() ? ICON_CLASSES.DOWNLOAD_ICON : ICON_CLASSES.CALENDAR,
    }
  ]
}