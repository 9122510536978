export const ABOUT_US_CONFIG = {
  sectionId: 'about-us',
  heading: 'About us',
  image: {
    src: '/rrr_parkrun_bognor.jpg',
    alt: 'Regis Road Runners at Parkrun',
  },
  copy: [
    'The Regis Road Runners are a UK Athletics affiliated running club, who enjoy running and motivating each other. We meet most Thursdays at 7pm, West Park, Aldwick, and we run along Bognor prom and back for approximately 40 minutes. Everyone finishes at roughly the same time and no one gets left behind.',
    'We have a varied range of ages & abilities, and we\'re always on the look out for new members! It doesn\'t matter whether you\'re a sub 40 minute 10K runner, jogger or walker, all are welcome. Membership not required.',
    'During the summer months, we have \'Away Days\' to places like East Dean, Centurion Way and Swanbourne Lake and run for 40 minutes to an hour.',
    'In December, we have our Festive Fun Runs followed by drinks and mince pies!'
  ]
}