import React, { useEffect, useState } from 'react'
import { useScript } from '../../hooks/useScript'
import { getCookie, removeAllCookies } from './CookieConsent.helpers'
import Link from '../Link/Link'
import { isProductionEnvironment } from '../../helpers/helpers'

export const CookieConsent = () => {
  const [canRunScripts, setCanRunScripts] = useState(false)
  const [isCookieConsentShown, setIsCookieConsentShown] = useState(false)

  const getAcceptanceCookieValue = getCookie('cookieAcceptance')

  const acceptCookies = () => {
    document.cookie = `cookieAcceptance=accepted;max-age=15778476;`
    setIsCookieConsentShown(false)
  }
  const declineCookies = () => {
    removeAllCookies()
    document.cookie = `cookieAcceptance=declined;max-age=15778476;`
    setIsCookieConsentShown(false)
  }

  useEffect(() => {
    if (getAcceptanceCookieValue) {
      if (getAcceptanceCookieValue === 'accepted') {
        setCanRunScripts(true)
      } else {
        return
      }
    } else {
      removeAllCookies()
      setIsCookieConsentShown(true)
    }
  }, [getAcceptanceCookieValue])

  const GATag = isProductionEnvironment ? 'G-7L8T04P0NJ' : 'G-L5RDJD0KXZ'

  useScript(`https://www.googletagmanager.com/gtag/js?id=${GATag}`, canRunScripts)

  return (
    <div className={`cookie-consent__container transition ${isCookieConsentShown ? 'cookie-consent__open' : ''}`}>
      <div>
        <div className="content no-fields">
          <h3 className="title">
            <div>We use cookies</div>
          </h3>
          <p className="description">
            We collect cookies to analyse our website traffic and performance. By using our website, you agree to the use of cookies. <Link url='/documents/cookie-and-privacy-policy.pdf'>Find out how we use cookies</Link>.
          </p>
          <div className="cookie-consent__buttons">
            <button type="button" className="cookie-consent__button" onClick={acceptCookies}>
              ACCEPT
            </button>
            <button type="button" className="cookie-consent__button" onClick={declineCookies}>
              DECLINE
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent
