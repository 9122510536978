import { useEffect, useState } from 'react'

import './App.css'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import SplitPanel from './components/SplitPanel/SplitPanel'

import { OverviewCards } from './components/OverviewCards/OverviewCards'
import { MEMBERSHIP_CONFIG } from './config/membership.config'
import { EVENTS_CONFIG } from './config/events.config'
import IntroductionPanel from './components/IntroductionPanel/IntroductionPanel'
import { ContactUsPanel } from './components/ContactUsPanel/ContactUsPanel'
import { CONTACT_US_CONFIG } from './config/contact-us.config'
import { ABOUT_US_CONFIG } from './config/about-us.config'
import SocialMediaFeed from './components/SocialMediaFeed/SocialMediaFeed'
import { SOCIAL_MEDIA_FEED_CONFIG } from './config/social-media-feed-config'
import { CookieConsent } from './components/CookieConsent/CookieConsent'
import Popup from './components/Popup/Popup'
import { POPUP_CONFIG, POPUP_KEYS } from './components/Popup/Popup.config'
import FeaturedEvent from './components/FeaturedEvent/FeaturedEvent'
import { EVENT_KEYS, FEATURED_EVENT_CONFIG } from './components/FeaturedEvent/FeaturedEvent.config'

const App = () => {
  const [showModal, setShowModal] = useState(false)

  const currentModal = POPUP_CONFIG[POPUP_KEYS.WELCOME]
  const currentFeatuedEvent = FEATURED_EVENT_CONFIG[EVENT_KEYS.ANNIVERSARY]
  const canShowFeaturedEvent = new Date() < currentFeatuedEvent.validUntil

  const onPopupClose = () => {
    setShowModal(false)
    localStorage.setItem('modalSeen', true)
    localStorage.setItem('modalSeenAt', new Date().toString())
  }

  useEffect(() => {
    if (localStorage.modalSeen === undefined && new Date() < currentModal.validUntil) {
      setShowModal(true)
    }
    if (!localStorage.modalSeenAt && Date.parse(localStorage.modalSeenAt) < currentModal.validUntil) {
      setShowModal(true)
    }
  })
  return (
    <>
      <div className='App'>
        <Header />
        <IntroductionPanel />
        {canShowFeaturedEvent && <FeaturedEvent {...currentFeatuedEvent} />}
        <SplitPanel {...ABOUT_US_CONFIG} />
        <OverviewCards {...EVENTS_CONFIG} />
        <SplitPanel {...MEMBERSHIP_CONFIG} />
        <SocialMediaFeed {...SOCIAL_MEDIA_FEED_CONFIG} />
        <ContactUsPanel {...CONTACT_US_CONFIG} />
        <Footer />
        <CookieConsent />
        <Popup {...currentModal} showModal={showModal} onClose={onPopupClose} />
      </div>
    </>
  )
}

export default App
