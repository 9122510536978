import React from 'react'
import Link from '../Link/Link'

export const Footer = () => {
  const getCurrentYear = new Date().getFullYear()

  return (
    <>
      <footer>
        <div className='footer__content'>
          <div className='footer__left footer__box'>
            <div className='footer__upper'>
              <p>England Athletics Registration: 2658502</p>
            </div>
          </div>
          <div className='footer__middle footer__box'>
            <p><Link url='/documents/regis-road-runners-terms-and-conditions.pdf'>Terms and Conditions</Link></p>
          </div>
          <div className='footer__right footer__box'>
            <p><Link url='/documents/cookie-and-privacy-policy.pdf'>Cookie and Privacy Policy</Link></p>
          </div>
        </div>
        <hr />
        <div className='footer__bottom'>
          <p>
            Images by <Link url='https://uk.linkedin.com/in/imogen-southgate-582772205'>Imogen Southgate</Link> | Built by <Link url='https://uk.linkedin.com/in/matt-kissell-8892a2149'>Matt Kissell</Link>
          </p>
          <p>© Regis Road Runners {getCurrentYear} | All Rights Reserved</p>
        </div>
      </footer>
    </>
  )
}

export default Footer